<div class="container-fluid">
    <form [formGroup]="detailform"  (ngSubmit)="onSubmit()">
            <div class="row">
                    <div class="col-md-12">
                            <div class="alert alert-danger alert-dismissible" *ngIf="isErrorAlertAPICall">
                                <a href="javascript:void(0);" class="close" (click)="onClickMsg('E')" aria-label="close">&times;</a>
                                <strong>Failure! </strong> {{ErrorMessageAPICall}}
                            </div>
                    </div>
                    </div>
        <div class="card">
            <div class="card-header font-weight-bold text-white" style="background-color:#5D87A1;">Anesthesia Quality Evaluation</div>
            <div class="card-body" [ngClass]="{'text-muted': IsCaseCompleted}">

                <div class="form-row pb-2 align-items-center">
                    <div class="col-sm-2">
                        <label for="txtFirstName" class="col-form-label align-content-end font-weight-bold">First Name: </label>
                    </div>
                    <div class="col-sm-2">                        
                        {{this.pFirstName}}
                    </div>
                    <div class="col-sm-2 pl-sm-2 pl-1">
                        <label for="txtLastName" class="col-form-label font-weight-bold">Last Name: </label>
                    </div>
                     <div class="col-sm-2">
                        {{this.pLastName}}
                    </div>
                    <div class="col-sm-2">
                        <label for="txtDOS" class="col-form-label font-weight-bold">Date Of Service: </label>
                    </div>
                     <div class="col-sm-2">                        
                        {{ this.pDOS }}
                    </div>
                </div>
                
                
                <div class="form-row align-items-center">
                    <div class="col-sm-2">
                        <label for="txtCaseRecID" class="form-check-label align-content-end font-weight-bold" >CaseRecID: </label>
                    </div>
                    <div class="col-sm-2">
                        {{this.pCaseRecId}}
                    </div>
                    <div class="col-sm-2 d-flex pl-sm-2 pl-1">
                        <label for="txtFacility" class="col-form-label font-weight-bold">Facility Name: </label>
                    </div>
                    <div class="col-sm-6">
                        {{this.pFacilityName}}
                    </div>

                </div>
                <hr color="black">
                <!-- ------------------------------------------------- -->
                
                <!-- <div class="form-row pl-1 align-items-center">
                    <label class="text-muted"> Exclusions for MIPS reporting: labor epidurals, post-op pain management, and ancillary procedures such as epidural blood patch, IV placement, emergency intubation, and ACLS.</label>
                </div> -->
                <div class="form-row pb-2 align-items-center">
                    <div class=" col-xs-4 col-sm-4 col-md-3 col-lg-3 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                        <!-- <label for="rdMacraApplicableYes" class="form-check-label font-weight-bold">MACRA Form Applicable</label> -->
                        <label for="rdMacraApplicableYes" class="form-check-label font-weight-bold">Type of Case Performed</label>                        
                    </div>
                    <!-- <div class="col-xs-2 col-sm-2 col-md-2 col-lg-1 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                        <div class="form-check">
                            <input type="radio" value="1" formControlName="MAC700"  name="MAC700" class="form-check-input" id="rdMacraApplicableYes" (click)="toggleMACRAForm($event)" >
                            <label class="form-check-label"> Yes</label>
                        </div>
                    </div> -->
                    <!-- <div class="col-xs-2 col-sm-2 col-md-2 col-lg-1  pb-0 pb-sm-0 pb-md-0 pb-lg-0"> -->
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-8  pb-0 pb-sm-0 pb-md-0 pb-lg-0">
                        <div class="form-check">
                            <input type="radio" value="0" formControlName="MAC700"  name="MAC700" class="form-check-input" id="rdMacraApplicableNo" (click)="toggleMACRAForm($event)">
                            <!-- <label class="form-check-label"> No </label> -->
                            <label class="form-check-label">  
                                Labor epidural only, post-op pain mgmt, or ancillary procedures such as epidural blood patch, IV placement, emergency intubation, and ACLS.
                            </label>
                        </div>
                    </div>
                    <!-- <div class="col-xs-4 col-sm-4 col-md-5 col-lg-7"></div> -->
                </div>
                <div class="form-row pb-2 align-items-center">
                    <div class=" col-xs-4 col-sm-4 col-md-3 col-lg-3 pb-2 pb-sm-0 pb-md-0 pb-lg-0">                        
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-8  pb-0 pb-sm-0 pb-md-0 pb-lg-0">
                        <div class="form-check">
                            <input type="radio" value="1" formControlName="MAC700"  name="MAC700" class="form-check-input" id="rdMacraApplicableYes" (click)="toggleMACRAForm($event)" >
                            <label class="form-check-label"> All other cases</label>
                        </div>
                    </div>
                    <!-- <div class="col-xs-4 col-sm-4 col-md-5 col-lg-7"></div> -->
                </div>
                <div *ngIf="!isANESQualitySectionDisplay">
                        <div class="form-row pb-2 pb-md-2 align-items-center ">
                            <div class="col-sm-3 pb-1 pb-sm-0 pb-md-0 pb-lg-0">
                                <label class="form-check-label font-weight-bold" for="drpAnesthesiaTypeDoc" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC701'].errors?.required===true}">Anesthesia Type</label>
                            </div>
                            <div class="col-sm-4 pb-0 pb-sm-0 pb-md-0 pb-lg-0">
                                <select formControlName="MAC701" class="form-control" [ngClass]="{'border-danger': isFormSubmitted && detailform.controls['MAC701'].errors?.required===true}" (change)="onChangeAnesType()"  id="drpAnesthesiaTypeDoc">
                                    <option value="">--Select--</option>
                                    <option *ngFor="let val of this.AnesTypeDocList" value={{val.groupAttributeSubItemId}}>
                                            {{val.groupAttributeSubItem}}
                                    </option>
                                </select> 
                            </div>
                            <div class="col-sm-5"></div>
                        </div>
                        <div class="form-row pb-2 pb-sm-2 align-items-center">
                            <div class="col-sm-3  col-md-3 col-lg-3 col-xs-3 pb-1 pb-sm-0 pb-md-0 pb-lg-0">
                                <label class="form-check-label font-weight-bold" for="drpASAStatusDoc" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC702'].errors?.required===true}">ASA Status</label>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4 col-xs-4 pb-1 pb-sm-0 pb-md-0 pb-lg-0">
                                <select formControlName="MAC702"  class="form-control" [ngClass]="{'border-danger': isFormSubmitted && detailform.controls['MAC702'].errors?.required===true}" id="drpASAStatusDoc" (change)="onChangeASA($event)">
                                    <option value="">--Select--</option>
                                    <option *ngFor="let val of this.ASAStatus" value={{val.groupAttributeSubItemId}}>
                                        {{val.groupAttributeSubItem}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-2 col-md-1 col-lg-1 col-xs-1 pb-1  pr-sm-1 ">
                                <label for="rdEmergencyYes" class="form-check-label font-weight-bold"  [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC703'].errors?.required===true}">Emergency</label> 
                            </div>
                            <div class="col-sm-2 col-md-2 col-lg-1 col-xs-1  pb-1  pl-sm-2 pl-md-5">
                                <div class="form-check">
                                    <input type="radio" formControlName="MAC703" value="1" class="form-check-input"  id="rdEmergencyYes" name="MAC703" (click)="ClickMAC703($event)" >
                                    <label class="form-check-label mr-10"  [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC703'].errors?.required===true}">
                                       Yes
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-1 col-md-2 col-lg-1 col-xs-1 pb-1 pl-md-5 ">
                                <div class="form-check">
                                    <input type="radio"  formControlName="MAC703" class="form-check-input"   value="0" id="rdEmergencyNo" name="MAC703" (click)="ClickMAC703($event)" >
                                    <label class="form-check-label mr-10" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC703'].errors?.required===true}">
                                        No
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-0 col-md-0 col-lg-2 col-xs-2"></div>
                        </div>                        
                        <div class="form-row pb-2 pb-sm-2 align-items-center">
                            <div class=" col-xs-3 col-sm-3 col-md-3 col-lg-3 pb-0 pb-sm-0 pb-md-0 pb-lg-0">
                                <label for="rdMacraApplicableYes" class="form-check-label font-weight-bold">Age</label>
                            </div>
                            <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                <div *ngFor="let item of AgeList;" >
                                    <div *ngIf="item.groupAttributeSubItemId==='MAC709A'">
                                        <div class="form-check">
                                            <input type="radio" value="1" formControlName="MAC709A"  name="MAC709A" class="form-check-input" id="MAC709A" (click)="AgeLoadMeasuresNew('MAC709A');SetChangeValues();" >
                                            <label class="form-check-label" for="MAC709A" >
                                                {{item.groupAttributeSubItem}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-3 col-sm-2 col-md-2 col-lg-1 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                <div *ngFor="let item of AgeList;" >
                                    <div *ngIf="item.groupAttributeSubItemId==='MAC709B'">
                                        <div class="form-check">
                                            <input type="radio" value="1" formControlName="MAC709B"  name="MAC709B" class="form-check-input" id="MAC709B" (click)="AgeLoadMeasuresNew('MAC709B');SetChangeValues();">
                                            <label class="form-check-label" for="MAC709B">
                                                 {{item.groupAttributeSubItem}}                                         
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                <div *ngFor="let item of AgeList;">
                                    <div *ngIf="item.groupAttributeSubItemId==='MAC709C'">
                                        <div class="form-check">
                                            <input type="radio" value="1" formControlName="MAC709C"  name="MAC709C" class="form-check-input" id="MAC709C" (click)="AgeLoadMeasuresNew('MAC709C');SetChangeValues();">
                                            <label class="form-check-label" for="MAC709C">
                                                {{item.groupAttributeSubItem}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-5 col-sm-6 col-md-3 col-lg-6"></div>
                            <!-- <div class="col-sm-0 col-md-0 col-lg-0 col-xs-0"></div> -->
                        </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isDisplay">

            <!--  Start  -->
            <div class="card" id="accordion404" >
                    <div class="card-header font-weight-bold" id="header404"   style="background-color:#D5DCE6">
                        <div class="form-row align-items-center" >
                            <div class="col-sm-11 col-xs-11 col-md-11 col-lg-11" [ngClass]="{'text-muted': IsMIPS404NA}">
                                MIPS 404 - Anesthesiology Smoking Abstinence (18 & Older)
                            </div>
                            <div class="col-sm-1 col-xs-1 col-md-1 col-lg-1">
                                <a [ngClass]="(IsMIPS404NA=== false)?'btn btn-link':'btn btn-link collapsed disabled-link'"  data-toggle="collapse" data-target="#collapse404" aria-expanded={{IsMIPS404NA}} aria-controls="collapse404">
                                    <i class="fa fa-angle-double-down fa-2x" [ngStyle]="{'color': (IsMIPS404NA=== false) ? 'black' : 'gray' }" aria-hidden="true"> </i>
                                </a>
                            </div>
                           
                        </div>
                    </div>
                    <div id="collapse404" [ngClass]="(IsMIPS404NA=== false)?'collapse show':'collapse'" aria-labelledby="header404" data-parent="#accordion404">
                        <div class="card-body">
                            <div *ngFor="let item of MIPS404List" [ngClass]="{'text-muted': IsCaseCompleted}" >  
                                <div *ngIf="item.groupAttributeId==='MAC017'" class="form-row pb-2 pb-sm-2 pb-md-2 pb-lg-0 align-items-center mb-0">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 ">
                                        <div class=" form-check" *ngIf="IsMIPS404NA">
                                            <input formControlName="MAC017" class="form-check-input" type="checkbox" value="1"  id="chkMIPS404NA" name="MAC017" (ngModelChange)="onChangeMIPS404NA($event);" (change)="SetChangeValues();" >
                                            <label class="form-check-label " for="chkMIPS404NA">
                                                {{item.groupAttributeName}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="item.groupAttributeId==='MAC014'"  [ngClass]="{'text-muted': MIPS404Disabled}" class="form-row pb-2 pb-sm-2 pb-md-2 pb-lg-0 align-items-center">
                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class=" form-check">
                                            <label class="form-check-label font-weight-bold" 
                                            [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC014'].errors?.required ===true}"
                                            >{{item.groupAttributeName}}</label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class=" form-check">
                                            <input formControlName="MAC014" class="form-check-input" type="radio" value="1" id="rdPtCurrentSmokerYes" name="MAC014" (change)="onChangeCurrentSmoker($event)">
                                            <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC014'].errors?.required ===true}" for="rdPtCurrentSmokerYes">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-0 pb-sm-2 pb-md-0 pb-lg-0">
                                        <div class=" form-check">
                                            <input  formControlName="MAC014" class="form-check-input"  type="radio" value="0" id="rdPtCurrentSmokerNo" name="MAC014" (change)="onChangeCurrentSmoker($event)">
                                            <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC014'].errors?.required ===true}" for="rdPtCurrentSmokerNo">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-4 col-md-4 col-lg-6"></div>
                                </div>
                                
                                <div *ngIf="item.groupAttributeId==='MAC015'" [ngClass]="{'text-muted': (MIPS404Disabled || MAC015Disabled)}"  class="form-row pb-1 pb-sm-2 pb-md-2 align-items-center">
                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class=" form-check">
                                            <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': hasErrorElectiveCase===true}">
                                                    {{item.groupAttributeName}}</label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class=" form-check">
                                            <input formControlName="MAC015" class="form-check-input" type="radio" value="1" id="rdPtReceivSmokingYes" name="MAC015" (change)="onChangePriorInstruction($event)">
                                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorElectiveCase===true}" for="rdPtReceivSmokingYes">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5">
                                        <div class=" form-check">
                                            <input formControlName="MAC015" class="form-check-input" type="radio" value="0" id="rdPtReceivSmokingNo" name="MAC015" (change)="onChangePriorInstruction($event)">
                                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorElectiveCase===true}" for="rdPtReceivSmokingNo">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-4 col-md-4 col-lg-6"></div>
                                </div>
                                <div *ngIf="item.groupAttributeId==='MAC015A'" [ngClass]="{'text-muted': (MIPS404Disabled || MAC015ADisabled)}"  class="form-row pb-1 pb-sm-2 pb-md-2 align-items-center">
                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class=" form-check">
                                            <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': hasErrorPtabstained===true}">
                                                    {{item.groupAttributeName}}</label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class=" form-check">
                                            <input formControlName="MAC015A" class="form-check-input" type="radio" value="1" id="rdabstainedsmokeYes" name="MAC015A" (change)="onChangePtabstainedsmoking($event)">
                                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPtabstained===true}" for="rdabstainedsmokeYes">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5">
                                        <div class=" form-check">
                                            <input formControlName="MAC015A" class="form-check-input" type="radio" value="0" id="rdabstainedsmokeNo" name="MAC015A" (change)="onChangePtabstainedsmoking($event)">
                                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPtabstained===true}" for="rdabstainedsmokeNo">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-4 col-md-4 col-lg-6"></div>
                                </div>
                                <div *ngIf="item.groupAttributeId==='MAC015B'" [ngClass]="{'text-muted': (MIPS404Disabled || MAC015BDisabled)}"  class="form-row pb-1 pb-sm-2 pb-md-2 align-items-center">
                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                            <div class="form-check">
                                                <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': hasErrorPtabstainedDOS===true}">
                                                        {{item.groupAttributeName}}</label>
                                            </div>
                                        </div>
                                        <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                            <div class=" form-check">
                                                <input formControlName="MAC015B" class="form-check-input" type="radio" value="1" id="rdabstainedsmokeYesMAC015B" name="MAC015B" (change)="onChangePtabstainedsmokingMAC015B($event)">
                                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPtabstainedDOS===true}" for="rdabstainedsmokeYesMAC015B">
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5">
                                            <div class=" form-check">
                                                <input formControlName="MAC015B" class="form-check-input" type="radio" value="0" id="rdabstainedsmokeNoMAC015B" name="MAC015B" (change)="onChangePtabstainedsmokingMAC015B($event)">
                                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPtabstainedDOS===true}" for="rdabstainedsmokeNoMAC015B">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-sm-4 col-md-4 col-lg-6"></div>
                                    </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="card" id="accordion477">
                    <div class="card-header font-weight-bold" id="header477" style="background-color:#D5DCE6">
                        <div class="form-row align-items-center">
                            <div class="col-sm-11 col-xs-11 col-md-11 col-lg-11" [ngClass]="{'text-muted': IsMIPS477NA}">
                                MIPS 477 - Multi-Modal Pain Management (18 & older)
                            </div>
                            <div class="col-sm-1 col-xs-1 col-md-1 col-lg-1">
                                <a [ngClass]="(IsMIPS477NA=== false)?'btn btn-link':'btn btn-link collapsed disabled-link'" href="javascript:void(0);" data-toggle="collapse" data-target="#collapse477" aria-expanded={{IsMIPS477NA}} aria-controls="collapse477">
                                    <i class="fa fa-angle-double-down fa-2x" [ngStyle]="{'color': (IsMIPS477NA=== false) ? 'black' : 'gray' }" aria-hidden="true"> </i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="collapse477" [ngClass]="(IsMIPS477NA=== false)?'collapse show':'collapse'" aria-labelledby="header477" data-parent="#accordion477">    
                            <div class="card-body">
                                <div class="form-row pb-1 align-items-center">
                                    <label class="text-muted">NSAIDS, NMDA Antagonists, APAP, Gabapentinoids, Nerve Block, Steroids, Local Anesthetic</label>
                                </div>
                                <div *ngFor="let item of MIPS477List" [ngClass]="{'text-muted': IsCaseCompleted}"> 
                                <div *ngIf="item.groupAttributeId==='MAC223'" class="form-row  pb-1 pb-md-2 align-items-center">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class=" form-check" *ngIf="IsMIPS477NA">
                                            <input formControlName="MAC223" class="form-check-input" type="checkbox"  id="chkMIPS477NA" name="MAC223" (ngModelChange)="onChangeMIPS477NA($event)" (change)="SetChangeValues();" >
                                            <label class="form-check-label" for="chkMIPS477NA">
                                                {{item.groupAttributeName}}
                                            </label>
                                        </div>
                                    </div>
                                </div> 
                                <div *ngIf="item.groupAttributeId==='MAC221'"  [ngClass]="{'text-muted': MIPS477Disabled}" class="form-row pb-1 pb-sm-2 pb-md-2 align-items-center">
                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class=" form-check">
                                            <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': isFormSubmitted && hasErrorNonemergentcase===true}">
                                                    {{item.groupAttributeName}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class=" form-check">
                                            <input formControlName="MAC221" class="form-check-input" type="radio" value="1" id="rdNonEmergentYes" name="MAC221" (change)="onChange477MultiPainMgmt($event)">
                                            <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && hasErrorNonemergentcase===true}" for="rdNonEmergentYes">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class="form-check">
                                            <input formControlName="MAC221" class="form-check-input" type="radio" value="0" id="rdNonEmergentNo" name="MAC221" (change)="onChange477MultiPainMgmt($event)">
                                            <label class="form-check-label" [ngClass]="{'text-danger':  isFormSubmitted && hasErrorNonemergentcase===true}" for="rdNonEmergentNo">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-4 col-md-4 col-lg-6"></div>
                                </div>
                                <!-- <div *ngIf="item.groupAttributeId==='MAC222'"  [ngClass]="{'text-muted': (MIPS477Disabled || MAC222Disabled)}" class="form-row pb-0 pb-sm-2 pb-md-2 align-items-center">
                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class="form-check">
                                            <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': hasErrorOpenLog===true}">
                                                    {{item.groupAttributeName}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class=" form-check">
                                            <input formControlName="MAC222" class="form-check-input" type="radio" value="1" id="rdNonEmergentYes" name="MAC222" (change)="onChange477OpenLap($event)">
                                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorOpenLog===true}" for="rdNonEmergentYes">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class="form-check">
                                            <input formControlName="MAC222" class="form-check-input" type="radio" value="0" id="rdNonEmergentNo" name="MAC222" (change)="onChange477OpenLap($event)">
                                            <label class="form-check-label" [ngClass]="{'text-danger':  hasErrorOpenLog===true}" for="rdNonEmergentNo">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-4 col-md-4 col-lg-6"></div>
                                </div> -->
                                <div *ngIf="item.groupAttributeId==='MAC222A'"  [ngClass]="{'text-muted': (MIPS477Disabled || MAC222ADisabled)}" class="form-row pl-5 pb-2 pb-sm-2 pb-md-2 pb-lg-0 align-items-center">
                                    <div class="form-check">
                                        <input formControlName="MAC222A" class="form-check-input" type="radio" value="1" id="chkMultiModalPM" name="MAC222A" (change)="onChange477MultiPainMgmtSub($event,'MAC222A')" >
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorNonemergentcaseYes===true}" for="chkMultiModalPM">
                                            {{item.groupAttributeName}}
                                        </label>
                                    </div>
                                </div>
                                <div *ngIf="item.groupAttributeId==='MAC222C'"  [ngClass]="{'text-muted': (MIPS477Disabled || MAC222CDisabled)}" class="form-row pl-5 pb-2 pb-sm-2 pb-md-2 pb-lg-0 align-items-center">
                                        <div class="form-check">
                                            <input formControlName="MAC222C" class="form-check-input" type="radio" value="1" id="chkMultiModalPMC" name="MAC222C" (change)="onChange477MultiPainMgmtSub($event,'MAC222C')" >
                                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorNonemergentcaseYes===true}" for="chkMultiModalPMC">
                                                {{item.groupAttributeName}}
                                            </label>
                                        </div>
                                    </div>
                                <div *ngIf="item.groupAttributeId==='MAC222B'"  [ngClass]="{'text-muted': (MIPS477Disabled || MAC222BDisabled)}" class="form-row pl-5 pb-2 pb-sm-2 pb-md-2 pb-lg-0 align-items-center">
                                    <div class="form-check">
                                        <input formControlName="MAC222B" class="form-check-input" type="radio" value="1" id="chkMedicalreasonMultiModal"  name="MAC222B" (change)="onChange477MultiPainMgmtSub($event,'MAC222B')" >
                                        <label class="form-check-label" [ngClass]="{'text-danger':  hasErrorNonemergentcaseYes===true}" for="chkMedicalreasonMultiModal">
                                            {{item.groupAttributeName}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="card" id="accordion430">
                    <div class="card-header font-weight-bold"  id="header430" style="background-color:#D5DCE6">
                        <div class="form-row align-items-center">
                            <div class="col-sm-11 col-xs-11 col-md-11 col-lg-11" [ngClass]="{'text-muted': IsMIPS430NA}">
                                MIPS 430 - Prevention of PONV - Combination Therapy Adults (18 & older)
                            </div>
                            <div class="col-sm-1 col-xs-1 col-md-1 col-lg-1">
                                <a [ngClass]="(IsMIPS430NA=== false)?'btn btn-link':'btn btn-link collapsed disabled-link'" href="javascript:void(0);" data-toggle="collapse" data-target="#collapse430" aria-expanded={{IsMIPS430NA}} aria-controls="collapse430">
                                    <i class="fa fa-angle-double-down fa-2x" [ngStyle]="{'color': (IsMIPS430NA=== false) ? 'black' : 'gray' }" aria-hidden="true"> </i>
                                </a>
                            </div>
                            
                        </div>
                    </div>
                    <div id="collapse430" [ngClass]="(IsMIPS430NA=== false)?'collapse show':'collapse'" aria-labelledby="header430" data-parent="#accordion430">    
                        <div class="card-body ">
                            <div class="form-row pb-1 align-items-center">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0">
                                    <label class="text-muted"> <b>Risk factors:</b> 1) Female 2) History of PONV 3) History of motion sickness 4) Non-smoker 5) Intended use of opioids for post-op analgesia</label>
                                </div>
                            </div>
                            
                            <div *ngFor="let item of MIPS430List" [ngClass]="{'text-muted': IsCaseCompleted}"> 
        
                            <div *ngIf="item.groupAttributeId==='MAC233'" class="form-row pb-1 pb-sm-2 pb-md-2 align-items-center">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pb-1">
                                    <div class=" form-check" *ngIf="IsMIPS430NA">
                                        <input formControlName="MAC233" class="form-check-input" type="checkbox"  id="chkMIPS430NA" name="MAC233" (ngModelChange)="onChangeMIPS430NA($event)" (change)="SetChangeValues();" >
                                        <label class="form-check-label" for="chkMIPS430NA">
                                            {{item.groupAttributeName}}
                                        </label>
                                    </div>
                                </div>
                            </div> 
                            <div  *ngIf="item.groupAttributeId==='MAC226'"  [ngClass]="{'text-muted': MIPS430Disabled}"  class="form-row pb-1 pb-sm-2 pb-md-2 align-items-center">
                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC226'].errors?.required===true}">
                                                {{item.groupAttributeName}}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <input  formControlName="MAC226" class="form-check-input" type="radio" value="1" id="rdPtRecInhalationYes" name="MAC226" (change)="onChange430PtReceivedInhal($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC226'].errors?.required===true}" for="rdPtRecInhalationYes">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5">
                                    <div class=" form-check">
                                        <input  formControlName="MAC226" class="form-check-input" type="radio" value="0" id="rdPtRecInhalationNo" name="MAC226" (change)="onChange430PtReceivedInhal($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC226'].errors?.required===true}" for="rdPtRecInhalationNo">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-6 col-sm-4 col-md-4 col-lg-6"></div>
                            </div>
                            <div *ngIf="item.groupAttributeId==='MAC227'"  [ngClass]="{'text-muted': (MIPS430Disabled || MAC227Disabled)}"  class="form-row pb-1 pb-sm-2 pb-md-2 align-items-center">
                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': hasErrorPatientreceivedinhal===true}">
                                                {{item.groupAttributeName}}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <input  formControlName="MAC227" class="form-check-input" type="radio" value="1" id="rdPONVYes" name="MAC227" (change)="onChange430PtExhibitsPONV($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientreceivedinhal===true}" for="rdPONVYes">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <input formControlName="MAC227"  class="form-check-input" type="radio" value="0" id="rdPONVNo" name="MAC227" (change)="onChange430PtExhibitsPONV($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientreceivedinhal===true}" for="rdPONVNo">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-6 col-sm-4 col-md-4 col-lg-6"></div>
                            </div>
                            <div *ngIf="item.groupAttributeId==='MAC228'"  [ngClass]="{'text-muted':(MIPS430Disabled || MAC228Disabled)}"  class="form-row pl-5 pb-2 pb-sm-2 pb-md-2 pb-lg-0 align-items-center">
                                <div class="form-check">
                                    <input formControlName="MAC228" class="form-check-input" type="radio" value="1"  id="chkMedReasTheraphy" name="MAC228" (change)="onChange430PtExhibitsPONVSub($event,'MAC228')">
                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits3 ===true}" for="chkMedReasTheraphy">
                                        {{item.groupAttributeName}}
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="item.groupAttributeId==='MAC231'"  [ngClass]="{'text-muted': (MIPS430Disabled || MAC231Disabled)}"  class="form-row pl-5 pb-2 pb-sm-2 pb-md-2 pb-lg-0 align-items-center">
                                <div class="form-check">
                                    <input  formControlName="MAC231" class="form-check-input" type="radio" value="1" id="chkMedReasTheraphy2" name="MAC231"  (change)="onChange430PtExhibitsPONVSub($event,'MAC231')">
                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits3 ===true}" for="chkMedReasTheraphy2">
                                        {{item.groupAttributeName}}
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="item.groupAttributeId==='MAC229'"  [ngClass]="{'text-muted': (MIPS430Disabled || MAC229Disabled)}"  class="form-row pl-5 pb-2 pb-sm-2 pb-md-2 pb-lg-0 align-items-center">
                                <div class="form-check">
                                    <input formControlName="MAC229" class="form-check-input" type="radio" value="1" id="chkMedReasTheraphy3" name="MAC229"  (change)="onChange430PtExhibitsPONVSub($event,'MAC229')">
                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits3 ===true}" for="chkMedReasTheraphy3">
                                        {{item.groupAttributeName}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" id="accordion463">
                    <div class="card-header font-weight-bold"  id="header463"  style="background-color:#D5DCE6">
                        <div class="form-row align-items-center">
                            <div class="col-sm-11 col-xs-11 col-md-11 col-lg-11" [ngClass]="{'text-muted': IsMIPS463NA}">
                                MIPS 463 - Prevention of PONV - Combination Therapy Pediatrics (3-17 yrs old)
                            </div>
                            <div class="col-sm-1 col-xs-1 col-md-1 col-lg-1">
                                <a [ngClass]="(IsMIPS463NA=== false)?'btn btn-link':'btn btn-link collapsed disabled-link'" href="javascript:void(0);" data-toggle="collapse" data-target="#collapse463" aria-expanded={{IsMIPS463NA}} aria-controls="collapse463">
                                    <i class="fa fa-angle-double-down fa-2x" [ngStyle]="{'color': (IsMIPS463NA=== false) ? 'black' : 'gray' }" aria-hidden="true"> </i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="collapse463" [ngClass]="(IsMIPS463NA=== false)?'collapse show':'collapse'" aria-labelledby="header463" data-parent="#accordion463">    
                        <div class="card-body">
                            <div class="form-row pb-1 align-items-center">
                                <label class="text-muted"><b>Risk factors:</b> 1) Surgery longer than 30 mins 2) Age > 3 y.o. 3) Strabismus surgery 4) History of POV or PONV in pt, parents or siblings</label>
                            </div>
                            <div *ngFor="let item of MIPS463List" [ngClass]="{'text-muted': IsCaseCompleted}"> 
                            <div *ngIf="item.groupAttributeId==='MAC240'" class="form-row pb-1 pb-md-2 align-items-center">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check" *ngIf="IsMIPS463NA">
                                        <input formControlName="MAC240" class="form-check-input" type="checkbox"  id="chkMIPS463NA" name="MAC240" (ngModelChange)="onChangeMIPS463NA($event);" (change)="SetChangeValues();" >
                                        <label class="form-check-label" for="chkMIPS463NA">
                                            {{item.groupAttributeName}}
                                        </label>
                                    </div>
                                </div>
                            </div> 


                            <div *ngIf="item.groupAttributeId==='MAC234'" [ngClass]="{'text-muted': (MIPS463Disabled || MAC234Disabled)}"  class="form-row pb-1  pb-md-2 align-items-center">
                                <div class="col-xs-4 col-sm-5 col-md-4  col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': isFormSubmitted && !!detailform.controls['MAC234'].errors?.required===true}">{{item.groupAttributeName}}</label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <input formControlName="MAC234" class="form-check-input"  type="radio" value="1" id="rd2MAC234Yes" name="MAC234" (change)="onChange463InhalYesNo($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger':isFormSubmitted && !!detailform.controls['MAC234'].errors?.required===true}" for="rd2MAC234Yes">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5">
                                    <div class="form-check">
                                        <input  formControlName="MAC234" class="form-check-input" type="radio" value="0" id="rd2MAC234No" name="MAC234" (change)="onChange463InhalYesNo($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger':isFormSubmitted && !!detailform.controls['MAC234'].errors?.required===true}" for="rd2MAC234No">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-6 col-sm-3 col-md-4 col-lg-6"></div>
                            </div>



                            <div *ngIf="item.groupAttributeId==='MAC232'"   [ngClass]="{'text-muted': MIPS463Disabled}"  class="form-row pb-1 pb-sm-2 align-items-center">
                                <div class="col-xs-4 col-sm-5 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger':isFormSubmitted && !!detailform.controls['MAC232'].errors?.required===true}" >
                                                <!-- {{item.groupAttributeName}} -->
                                        </label>
                                    </div>
                                </div>
                                <div  *ngIf="item.groupAttributeId==='MAC232'"   [ngClass]="{'text-muted': MIPS463Disabled}"  class="col-xs-3 col-sm-4 col-md-4 col-lg-3 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <input formControlName="MAC232" class="form-check-input" type="radio" value="0" id="rdReceivedInhalationalYes" name="MAC232"  (change)="onChange463PtReceivedInhal($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasError232===true}" for="rdReceivedInhalationalYes">
                                            {{MAC232Text1}}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-5 col-sm-3 col-md-4 col-lg-5"></div>
                            </div>
                            <div *ngIf="item.groupAttributeId==='MAC232'" [ngClass]="{'text-muted': MIPS463Disabled}"  class="form-row  pb-2 pb-sm-2 pb-md-0 pb-lg-0 ">
                                <div class="col-xs-4 offset-xs-5 col-sm-4 offset-sm-5 col-md-4 offset-md-4 col-lg-4 offset-lg-4 pl-5">
                                    <div class=" form-check">
                                        <input formControlName="MAC232" class="form-check-input" type="radio" value="1" id="rdReceivedInhalationalNo" name="MAC232"  (change)="onChange463PtReceivedInhal($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasError232===true}" for="rdReceivedInhalationalNo">
                                            {{MAC232Text2}}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-3 col-sm-3 col-md-4 col-lg-4"></div>
                            </div>
                            
                            <div *ngIf="item.groupAttributeId==='MAC235'" [ngClass]="{'text-muted': (MIPS463Disabled || MAC235Disabled)}"  class="form-row pb-1  pb-md-2 align-items-center">
                                <div class="col-xs-4 col-sm-5 col-md-4  col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': hasErrorPatientexhibits2===true}">{{item.groupAttributeName}}</label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <input formControlName="MAC235" class="form-check-input"  type="radio" value="1" id="rd2POVYes" name="MAC235" (change)="onChange463PtExhibitsPONV($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits2===true}" for="rd2POVYes">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5">
                                    <div class="form-check">
                                        <input  formControlName="MAC235" class="form-check-input" type="radio" value="0" id="rd2POVNo" name="MAC235" (change)="onChange463PtExhibitsPONV($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits2===true}" for="rd2POVNo">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-6 col-sm-3 col-md-4 col-lg-6"></div>
                            </div>
                            <div *ngIf="item.groupAttributeId==='MAC237'" [ngClass]="{'text-muted': (MIPS463Disabled || MAC237Disabled)}"  class="form-row pl-5 pb-2 pb-sm-2 pb-md-2 pb-lg-0 align-items-center">
                                <div class="form-check">
                                    <input formControlName="MAC237" class="form-check-input" value="1" type="radio" id="chkMedReasTheraphy11" name="MAC237" (change)="onChange463PtExhibitsPONVSub($event,'MAC237')">
                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits2Dtl===true}" for="chkMedReasTheraphy11">
                                        {{item.groupAttributeName}}
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="item.groupAttributeId==='MAC239'" [ngClass]="{'text-muted':(MIPS463Disabled || MAC239Disabled)}"  class="form-row pl-5 pb-2 pb-sm-2 pb-md-2 pb-lg-0 align-items-center">
                                <div class="form-check">
                                    <input  formControlName="MAC239" class="form-check-input" value="1" type="radio"  id="chkMedReasTheraphy22" name="MAC239" (change)="onChange463PtExhibitsPONVSub($event,'MAC239')" >
                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits2Dtl===true}" for="chkMedReasTheraphy22">
                                        {{item.groupAttributeName}}
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="item.groupAttributeId==='MAC238'" [ngClass]="{'text-muted': (MIPS463Disabled || MAC238Disabled)}"  class="form-row pl-5 pb-2 pb-sm-2 pb-md-2 pb-lg-0 align-items-center">
                                <div class="form-check">
                                    <input formControlName="MAC238" class="form-check-input" value="1" type="radio"  id="chkMedReasTheraphy33" name="MAC238" (change)="onChange463PtExhibitsPONVSub($event,'MAC238')">
                                    <label  class="form-check-label" [ngClass]="{'text-danger': hasErrorPatientexhibits2Dtl===true}" for="chkMedReasTheraphy33">
                                        {{item.groupAttributeName}}
                                    </label>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" id="accordionPQRAnes4" *ngIf="enablePQRAnes4">
                <div class="card-header font-weight-bold"  id="headerPQRAnes4"  style="background-color:#D5DCE6">
                    <div class="form-row align-items-center">
                        <div class="col-sm-11 col-xs-11 col-md-11 col-lg-11" [ngClass]="{'text-muted': IsPQRAnes4NA}">
                            PQRAnes4 - Limiting Use of Desflurane-Based Inhalational Anesthesia (all patients)
                        </div>
                        <div class="col-sm-1 col-xs-1 col-md-1 col-lg-1">
                            <a [ngClass]="(IsPQRAnes4NA=== false)?'btn btn-link':'btn btn-link collapsed disabled-link'" href="javascript:void(0);" data-toggle="collapse" data-target="#collapsePQRAnes4" aria-expanded={{IsPQRAnes4NA}} aria-controls="collapsePQRAnes4">
                                <i class="fa fa-angle-double-down fa-2x" [ngStyle]="{'color': (IsPQRAnes4NA=== false) ? 'black' : 'gray' }" aria-hidden="true"> </i>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="collapsePQRAnes4" [ngClass]="(IsPQRAnes4NA=== false)?'collapse show':'collapse'" aria-labelledby="headerPQRAnes4" data-parent="#accordionPQRAnes4">    
                    <div class="card-body">
                        
                        <div *ngFor="let item of PQRAnes4List" [ngClass]="{'text-muted': IsCaseCompleted}"> 
                            <div *ngIf="item.groupAttributeId==='MAC317'" class="form-row pb-1 pb-md-2 align-items-center">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check" *ngIf="IsPQRAnes4NA">
                                        <input formControlName="MAC317" class="form-check-input" type="checkbox"  id="chkPQRAnes4NA" name="MAC317" (ngModelChange)="onChangePQRAnes4NA($event);" (change)="SetChangeValues();" >
                                        <label class="form-check-label" for="chkPQRAnes4NA">
                                            {{item.groupAttributeName}}
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div *ngIf="item.groupAttributeId==='MAC318'" [ngClass]="{'text-muted': (PQRAnes4Disabled || MAC318Disabled)}"  class="form-row pb-1  pb-md-2 align-items-center">
                                <div class="col-xs-4 col-sm-5 col-md-4  col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': isFormSubmitted && !!detailform.controls['MAC318'].errors?.required===true}">
                                            {{item.groupAttributeName}}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <input formControlName="MAC318" class="form-check-input"  type="radio" value="1" id="rd2MAC318Yes" name="MAC318" (change)="onChangePQRAnes4PtReceivedInhal($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger':isFormSubmitted && !!detailform.controls['MAC318'].errors?.required===true}" for="rd2MAC318Yes">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5">
                                    <div class="form-check">
                                        <input  formControlName="MAC318" class="form-check-input" type="radio" value="0" id="rd2MAC318No" name="MAC318" (change)="onChangePQRAnes4PtReceivedInhal($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger':isFormSubmitted && !!detailform.controls['MAC318'].errors?.required===true}" for="rd2MAC318No">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-6 col-sm-3 col-md-4 col-lg-6"></div>
                            </div>

                            <div *ngIf="item.groupAttributeId==='MAC319'" [ngClass]="{'text-muted': (PQRAnes4Disabled || MAC319Disabled)}"  class="form-row pb-1  pb-md-2 align-items-center">
                                <div class="col-xs-4 col-sm-5 col-md-4  col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': hasErrorPQRAnes4PtRcvdDesflurane===true}">
                                            {{item.groupAttributeName}}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <input formControlName="MAC319" class="form-check-input"  type="radio" value="1" id="rd2MAC319Yes" name="MAC319" (change)="onChangePQRAnes4PtRcvdDesflurane($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPQRAnes4PtRcvdDesflurane===true}" for="rd2MAC319Yes">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5">
                                    <div class="form-check">
                                        <input  formControlName="MAC319" class="form-check-input" type="radio" value="0" id="rd2MAC319No" name="MAC319" (change)="onChangePQRAnes4PtRcvdDesflurane($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorPQRAnes4PtRcvdDesflurane===true}" for="rd2MAC319No">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-6 col-sm-3 col-md-4 col-lg-6"></div>
                            </div>
                        </div>

                        <div class="form-row pt-3 align-items-center">
                            <label class="text-muted">
                                Includes patients who receive combined potent volatile inhalation anesthesia and total intravenous anesthesia.
                                This measure applied to both induction and maintenance.
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" id="accordion424">
                    <div class="card-header font-weight-bold"   id="header424" style="background-color:#D5DCE6;">
                        <div class="form-row align-items-center">
                            <div class="col-sm-11 col-xs-11 col-md-11 col-lg-11" [ngClass]="{'text-muted': IsMIPS424NA}">
                                MIPS 424 - Perioperative Temperature Management (all patients)
                            </div>
                            <div class="col-sm-1 col-xs-1 col-md-1 col-lg-1">
                                <a  [ngClass]="(IsMIPS424NA=== false)?'btn btn-link':'btn btn-link collapsed disabled-link'" href="javascript:void(0);" data-toggle="collapse" data-target="#collapse424" aria-expanded={{IsMIPS424NA}} aria-controls="collapse424">
                                    <i class="fa fa-angle-double-down fa-2x" [ngStyle]="{'color': (IsMIPS424NA=== false) ? 'black' : 'gray' }" aria-hidden="true"> </i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="collapse424" [ngClass]="(IsMIPS424NA=== false)?'collapse show':'collapse'" aria-labelledby="header424" data-parent="#accordion424">    
                        <div class="card-body ">
                        <div *ngFor="let item of MIPS424List" [ngClass]="{'text-muted': IsCaseCompleted}">     
                            <div *ngIf="item.groupAttributeId==='MAC032'" class="form-row pb-2 pb-sm-0 pb-md-2 pb-lg-0 align-items-center">
                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0">
                                    <div class=" form-check">
                                        <label class="form-check-label font-weight-bold"  [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC032'].errors?.required===true}">
                                                {{item.groupAttributeName}}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <input formControlName="MAC032" class="form-check-input" type="radio" value="1" id="rdAnesthesia60MinYes" name="MAC032" (change)="onChangeAnesthesiaCase60($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC032'].errors?.required===true}" for="rdAnesthesia60MinYes">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <input formControlName="MAC032" class="form-check-input" type="radio" value="0" id="rdAnesthesia60MinNo" name="MAC032" (change)="onChangeAnesthesiaCase60($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC032'].errors?.required===true}" for="rdAnesthesia60MinNo">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-6 col-sm-4 col-md-4 col-lg-6"></div>
                            </div>
                            <div *ngIf="item.groupAttributeId==='MAC032A'" [ngClass]="{'text-muted': (MIPS242Disabled || MAC032ADisabled)}" class="form-row pl-5 pb-2 pb-sm-2 pb-md-2 pb-lg-0 align-items-center">
                                <div class="form-check" *ngIf="!HideMIPS424SubItem">
                                    <input  formControlName="MAC032A" class="form-check-input" type="radio" value="1"  id="chkDocumentWithin30Min" name="MAC032A" (change)="onChangeAnesthesiaCase60Sub($event,'MAC032A')">
                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAnesCase60===true}" for="chkDocumentWithin30Min">
                                        {{item.groupAttributeName}}
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="item.groupAttributeId==='MAC032B'" [ngClass]="{'text-muted': (MIPS242Disabled || MAC032BDisabled)}" class="form-row pl-5 pb-2 pb-sm-2 pb-md-2 pb-lg-0 align-items-center">
                                <div class="form-check" *ngIf="!HideMIPS424SubItem">
                                    <input  formControlName="MAC032B" class="form-check-input" type="radio" value="1"  id="chkMedicalReson1Body" name="MAC032B" (change)="onChangeAnesthesiaCase60Sub($event,'MAC032B')">
                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAnesCase60===true}" for="chkMedicalReson1Body">
                                        {{item.groupAttributeName}}
                                    </label>
                                </div>
                            </div>
                            <div  *ngIf="item.groupAttributeId==='MAC032C'" [ngClass]="{'text-muted': (MIPS242Disabled || MAC032CDisabled)}" class="form-row pl-5 pb-1 pb-sm-2 pb-md-2 pb-lg-0 align-items-center">
                                <div class="form-check" *ngIf="!HideMIPS424SubItem">
                                    <input formControlName="MAC032C"  class="form-check-input"  type="radio" value="1" id="chkBodyTemp" name="MAC032C" (change)="onChangeAnesthesiaCase60Sub($event,'MAC032C')">
                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAnesCase60===true}" for="chkBodyTemp">
                                        {{item.groupAttributeName}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" id="accordionAQI" *ngIf="!disablemeasure">
                    <div class="card-header font-weight-bold"  id="headerAQI" style="background-color:#D5DCE6">
                        <div class="form-row align-items-center">
                            <div class="col-sm-11 col-xs-11 col-md-11 col-lg-11" [ngClass]="{'text-muted': IsAQINA}">
                                AQI 69 - Intraoperative Antibiotic Redosing (18 & older)
                            </div>
                            <div class="col-sm-1 col-xs-1 col-md-1 col-lg-1">
                                <a [ngClass]="(IsAQINA=== false)?'btn btn-link':'btn btn-link collapsed disabled-link'" href="javascript:void(0);" data-toggle="collapse" data-target="#collapseAQI" aria-expanded={{IsAQINA}} aria-controls="collapseAQI">
                                    <i class="fa fa-angle-double-down fa-2x" [ngStyle]="{'color': (IsAQINA=== false) ? 'black' : 'gray' }" aria-hidden="true"> </i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="collapseAQI" [ngClass]="(IsAQINA=== false)?'collapse show':'collapse'" aria-labelledby="headerAQI" data-parent="#accordionAQI">    
                            <div class="card-body">
                                    <div *ngFor="let item of AQIList;" [ngClass]="{'text-muted': IsCaseCompleted}">
                                    <div *ngIf="item.groupAttributeId==='MAC312'" class="form-row pb-md-0 align-items-center">
                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0">
                                            <div class=" form-check" *ngIf="IsAQINA">
                                                <input formControlName="MAC312" class="form-check-input" type="checkbox"  id="chkMIPSAQINA" name="MAC312" (ngModelChange)="onChangeMIPSAQINA($event);"  (change)="SetChangeValues();">
                                                <label class="form-check-label" for="chkMIPSAQINA">
                                                    {{item.groupAttributeName}}
                                                </label>
                                            </div>
                                        </div>
                                    </div> 
                                    <div *ngIf="item.groupAttributeId==='MAC301'"  [ngClass]="{'text-muted': AQIDisabled}"  class="form-row pb-1 pb-md-0 align-items-center">
                                            <div class="col-xs-4 col-sm-5 col-md-4 col-lg-4 pl-0 pb-sm-0">
                                                <div class=" form-check">
                                                    <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': hasErrorAQI===true}">
                                                            Exclusions
                                                    </label>
                                                </div>
                                            </div>
                                            <div  *ngIf="item.groupAttributeId==='MAC301'" class="col-xs-3 col-sm-5 col-md-5 col-lg-3 pb-sm-0 pl-5">
                                                <div class=" form-check">
                                                    <input formControlName="MAC301" class="form-check-input" type="checkbox" value="" id="MAC301" name="MAC301" (change)="onChangeAQI($event,'MAC301')" >
                                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQI===true}" for="MAC301">
                                                            {{item.groupAttributeName}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-xs-5 col-sm-2 col-md-3 col-lg-5"></div>
                                            </div>
                
                                        <div class="form-row pb-md-1 align-items-center">
                                                <div class="col-xs-4 offset-xs-5 col-sm-5 offset-sm-5 col-md-5 offset-md-4 col-lg-4 offset-lg-4 pl-5 pb-sm-0">
                                                <div  *ngIf="item.groupAttributeId==='MAC302'"  [ngClass]="{'text-muted': AQIDisabled}" class="form-check">
                                                    <input formControlName="MAC302" class="form-check-input" type="checkbox" value="" id="MAC302" name="MAC302" (change)="onChangeAQI($event,'MAC302')" >
                                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQI===true}" for="MAC302">
                                                        {{item.groupAttributeName}}
                                                    </label>
                                                </div>
                                                </div>
                                                <div class="col-xs-3 col-sm-2 col-md-3 col-lg-4"></div>
                                            </div>
                                        <div class="form-row pb-1 pb-sm-2 pb-md-0 pb-lg-0  align-items-center">
                                                <div class="col-xs-4 offset-xs-6 col-sm-5 offset-sm-5 col-md-7 offset-md-4 col-lg-6 offset-lg-4 pl-5">
                                                    <div  *ngIf="item.groupAttributeId==='MAC303'"  [ngClass]="{'text-muted': AQIDisabled}"  class="form-check">
                                                        <input formControlName="MAC303" class="form-check-input" type="checkbox" value="" id="MAC303" name="MAC303" (change)="onChangeAQI($event,'MAC303')" >
                                                        <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQI===true}" for="MAC303">
                                                            {{item.groupAttributeName}}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-xs-3 col-sm-1 col-md-1 col-lg-2 "></div>
                                        </div>
                                <div  *ngIf="item.groupAttributeId==='MAC304'"  [ngClass]="{'text-muted': (AQIDisabled || MAC304Disabled) }" class="form-row pb-sm-0 pb-md-0 align-items-center">
                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class="form-check">
                                            <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': hasErrorAQI304===true}" >  {{item.groupAttributeName}}</label>
                                        </div>
                                    
                                    </div>
                                    <div class="col-xs-1 col-sm-3 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class=" form-check">
                                            <input formControlName="MAC304" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMAC304" name="MAC304" (change)="onChangeProcedure($event)">
                                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQI304===true}" for="rdNonEmergentYesMAC304">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-sm-0">
                                        <div class="form-check">
                                            <input formControlName="MAC304" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMAC304" name="MAC304" (change)="onChangeProcedure($event)">
                                            <label class="form-check-label"  [ngClass]="{'text-danger': hasErrorAQI304===true}" for="rdNonEmergentNoMAC304">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-3 col-md-4 col-lg-6"></div>
                                </div>
                                <div *ngIf="item.groupAttributeId==='MAC305'"  [ngClass]="{'text-muted': (AQIDisabled || MAC305Disabled)}" class="form-row pb-sm-2 pb-md-1 align-items-center">
                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                            <div class="form-check">
                                                <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': hasErrorAQIProcedure===true}"> {{item.groupAttributeName}}</label>
                                            </div>
                                        </div>
                                        <div class="col-xs-1 col-sm-3 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                            <div class=" form-check">
                                                <input formControlName="MAC305" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMAC305" name="MAC305" (change)="onChangeAQIPtreceived($event)">
                                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQIProcedure===true}" for="rdNonEmergentYesMAC305">
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-sm-0">
                                            <div class="form-check">
                                                <input formControlName="MAC305" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMAC305" name="MAC305" (change)="onChangeAQIPtreceived($event)">
                                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQIProcedure===true}" for="rdNonEmergentNoMAC305">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-sm-3 col-md-4 col-lg-6"></div>
                                    </div>
                                    <div *ngIf="item.groupAttributeId==='MAC305A'"  [ngClass]="{'text-muted': (AQIDisabled|| MAC305ADisabled || MAC305BSelected)}" class="form-row pb-sm-2 pb-md-1 align-items-center">
                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                                <div class=" form-check">
                                                    <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': hasErrorAQIPtreceived===true}"> {{item.groupAttributeName}}</label>
                                                </div>
                                            </div>
                                            <div class="col-xs-1 col-sm-3 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                                <div class=" form-check">
                                                    <input formControlName="MAC305A" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMACMAC305A" name="MAC305A" (change)="onChangeAQIantibiotic($event,'MAC305A')">
                                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAQIPtreceived===true}" for="rdNonEmergentYesMACMAC305A">
                                                        Yes
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-sm-0">
                                                <div class="form-check">
                                                    <input formControlName="MAC305A" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMACMAC305A" name="MAC305A" (change)="onChangeAQIantibiotic($event,'MAC305A')">
                                                    <label class="form-check-label" [ngClass]="{'text-danger':  hasErrorAQIPtreceived===true}" for="rdNonEmergentNoMACMAC305A">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-xs-6 col-sm-3 col-md-4 col-lg-6"></div>
                                        </div>
                                        <div *ngIf="item.groupAttributeId==='MAC305B'"  [ngClass]="{'text-muted': (AQIDisabled|| MAC305BDisabled || MAC305ASelected)}" class="form-row pb-sm-2 pb-md-1 align-items-center">
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                                    <div class=" form-check">
                                                        <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger':  hasErrorAQIPtreceived===true}"> {{item.groupAttributeName}}</label>
                                                    </div>
                                                </div>
                                                <div class="col-xs-1 col-sm-3 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                                    <div class=" form-check">
                                                        <input formControlName="MAC305B" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMAC305B" name="MAC305B" (change)="onChangeAQIantibiotic($event,'MAC305B')">
                                                        <label class="form-check-label" [ngClass]="{'text-danger':  hasErrorAQIPtreceived===true}" for="rdNonEmergentYesMAC305B">
                                                            Yes
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                                    <div class="form-check">
                                                        <input formControlName="MAC305B" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMAC305B" name="MAC305B" (change)="onChangeAQIantibiotic($event,'MAC305B')">
                                                        <label class="form-check-label" [ngClass]="{'text-danger':  hasErrorAQIPtreceived===true}" for="rdNonEmergentNoMAC305B">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-xs-6 col-sm-3 col-md-4 col-lg-6"></div>
                                            </div>
                                            <div *ngIf="item.groupAttributeId==='MAC309'" class="form-row align-items-left">
                                                <label class="text-muted"> {{item.groupAttributeName}}</label>
                                            </div>
                                    </div>   
                            </div>
                      </div>      
            </div>
            <!-- Anes Tier -->
            <div class="card" id="accordionAnesTier" *ngIf="!disablemeasure">
                    <div class="card-header font-weight-bold"   id="headerAnesTier" style="background-color:#D5DCE6">
                        <div class="form-row align-items-center">
                            <div class="col-sm-11 col-xs-11 col-md-11 col-lg-11" [ngClass]="{'text-muted': IsAnesTierNA}">
                                Anes 06 - Application of PeriOperative Medicine (POM) Evidence-based Elements for Total Joint Arthroplasty (18 & older)
                            </div>
                            <div class="col-sm-1 col-xs-1 col-md-1 col-lg-1">
                                <a [ngClass]="(IsAnesTierNA=== false)?'btn btn-link':'btn btn-link collapsed disabled-link'" href="javascript:void(0);" data-toggle="collapse" data-target="#collapseAnesTier" aria-expanded={{IsAnesTierNA}} aria-controls="collapseAnesTier">
                                    <i class="fa fa-angle-double-down fa-2x" [ngStyle]="{'color': (IsAnesTierNA=== false) ? 'black' : 'gray' }" aria-hidden="true"> </i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="collapseAnesTier" [ngClass]="(IsAnesTierNA=== false)?'collapse show':'collapse'" aria-labelledby="headerAnesTier" data-parent="#accordionAnesTier">    
                            <div class="card-body">
                                
                                    <div *ngFor="let item of AnesTierList;" [ngClass]="{'text-muted': IsCaseCompleted}"> 
                                        <div *ngIf="item.groupAttributeId==='MAC314'"  class="form-row pb-0 align-items-center pl-0">
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pb-2 pb-sm-2 pb-md-2 pb-lg-0">
                                                <div class=" form-check" *ngIf="IsAnesTierNA">
                                                    <input formControlName="MAC314" class="form-check-input" type="checkbox"  id="chkAnesTierNA" name="MAC314" (ngModelChange)="onChangeAnesTier($event);" (change)="SetChangeValues();" >
                                                    <label class="form-check-label" for="chkAnesTierNA">
                                                        {{item.groupAttributeName}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div> 
                                        <div  *ngIf="item.groupAttributeId==='MAC310'" [ngClass]="{'text-muted': AnesTierDisabled}" class="form-row pb-0 pb-sm-2 pb-md-2 align-items-center"> 
                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                                <div class=" form-check">
                                                    <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC310'].errors?.required===true}">  {{item.groupAttributeName}}</label>
                                                </div>
                                            </div>
                                            <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                                <div class=" form-check">
                                                    <input formControlName="MAC310" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMAC310" name="MAC310" (change)="onChangeMAC310($event)">
                                                    <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC310'].errors?.required===true}"  for="rdNonEmergentYesMAC310">
                                                        Yes
                                                    </label>
                                                </div>  
                                            </div>
                                            <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                                <div class="form-check">
                                                    <input formControlName="MAC310" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMAC310" name="MAC310" (change)="onChangeMAC310($event)">
                                                    <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC310'].errors?.required===true}" for="rdNonEmergentNoMAC310">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-xs-6 col-sm-4 col-md-4 col-lg-6"></div>
                                        </div>
                                                            
                                        <div *ngIf="item.groupAttributeId==='MAC311'" [ngClass]="{'text-muted': (AnesTierDisabled || MAC311Disabled)}" class="form-row pb-1 pb-sm-2 pb-md-2 align-items-center">
                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0">
                                                <div class=" form-check">
                                                    <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': hasErrorsMAC310===true}"> {{item.groupAttributeName}}</label>
                                                </div>
                                            </div>
                                            <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                                <div class=" form-check">
                                                    <input formControlName="MAC311" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMAC311" name="MAC311" (change)="onChangeMAC311($event)">
                                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsMAC310===true}" for="rdNonEmergentYesMAC311">
                                                        Yes
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                                <div class="form-check">
                                                    <input formControlName="MAC311" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMAC311" name="MAC311" (change)="onChangeMAC311($event)">
                                                    <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsMAC310===true}" for="rdNonEmergentNoMAC311">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-xs-6 col-sm-4 col-md-4 col-lg-6"></div>
                                        </div>
                                        <div *ngIf="item.groupAttributeId==='MAC310' && item.hasOwnProperty('groupSubAttribute')">
                                                <div class="form-row">
                                                    <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 pl-0 text-muted">
                                                        <ul *ngFor="let child of item.groupSubAttribute" >
                                                                <li >
                                                                    {{child.groupAttributeSubItem}}
                                                                </li>
                                                        </ul>  
                                                    </div> 
                                                </div>
                                            </div> 
                                        <div *ngIf="item.groupAttributeId==='MAC311' && item.hasOwnProperty('groupSubAttribute')">
                                            <div class="form-row">
                                                <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 pl-0 text-muted">
                                                    <ul *ngFor="let child of item.groupSubAttribute">
                                                        <li >
                                                                {{ child.groupAttributeSubItem }}
                                                        </li>
                                                    </ul> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>   
                            </div>
                    </div>       
            </div>            
            <!-- Anes Tier -->
            <div class="card" id="accordion76" *ngIf="!disablemeasure">
                <div class="card-header font-weight-bold"  id="header76" style="background-color:#D5DCE6">
                    <div class="form-row align-items-center">
                        <div class="col-sm-11 col-xs-11 col-md-11 col-lg-11" [ngClass]="{'text-muted': IsMIPS76NA}">
                            MIPS 76 - Prevention of Central Venous Catheter (CVC) Related Bloodstream Infections (all patients)
                        </div>
                        <div class="col-sm-1 col-xs-1 col-md-1 col-lg-1">
                            <a [ngClass]="(IsMIPS76NA=== false)?'btn btn-link':'btn btn-link collapsed disabled-link'" href="javascript:void(0);" data-toggle="collapse" data-target="#collapse76" aria-expanded={{IsMIPS76NA}} aria-controls="collapse76">
                                <i class="fa fa-angle-double-down fa-2x" [ngStyle]="{'color': (IsMIPS76NA=== false) ? 'black' : 'gray' }" aria-hidden="true"> </i>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="collapse76" [ngClass]="(IsMIPS76NA=== false)?'collapse show':'collapse'" aria-labelledby="header76" data-parent="#accordion76">    
                    <div class="card-body">
                        <div *ngFor="let item of MIPS76List" [ngClass]="{'text-muted': IsCaseCompleted}"> 
                        <div *ngIf="item.groupAttributeId==='MAC022'"  class="form-row pb-1 pb-sm-2 pb-md-2 align-items-end">
                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0">
                                <div class="form-check">
                                    <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC022'].errors?.required===true}">
                                            {{item.groupAttributeName}}</label>
                                </div>
                            </div>
                            <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                <div class="form-check">
                                    <input type="radio" formControlName="MAC022" value="1" class="form-check-input"  id="rdCentrallineYes" name="MAC022" (change)="onChangeCentralLine($event)">
                                    <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC022'].errors?.required===true}" for="rdCentrallineYes">
                                        Yes
                                    </label>
                                </div>
                            </div>
                            <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                <div class=" form-check">
                                    <input type="radio"  formControlName="MAC022" class="form-check-input"   value="0" id="rdCentrallineNo" name="MAC022" (change)="onChangeCentralLine($event)">
                                    <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC022'].errors?.required===true}" for="rdCentrallineNo">
                                        No
                                    </label>
                                </div>
                            </div>
                            <div class="col-xs-6 col-sm-4 col-md-4 col-lg-6">
                                
                            </div>
                        </div>
                        <div  *ngIf="item.groupAttributeId==='MAC022A'" [ngClass]="{'text-muted': (MIPS76Disabled || MAC022ADisabled )}"  class="form-row pl-5 pb-1 pb-sm-2 pb-md-2 align-items-center">
                            <div class="form-check">
                                <input formControlName="MAC022A" class="form-check-input" type="radio" value="1" id="chkmaximalsterile" name="MAC022A" (change)="onChangeCentralLineSub($event,'MAC022A')">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsCentral===true}" for="chkmaximalsterile">
                                    {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="item.groupAttributeId==='MAC022C'" [ngClass]="{'text-muted': (MIPS76Disabled || MAC022CDisabled)}"  class="form-row pl-5 pb-1 pb-sm-2 pb-md-2 align-items-center">
                            <div class="form-check">
                                <input formControlName="MAC022C" class="form-check-input" type="radio" value="1" id="chkmaximalsterileNo" name="MAC022C" (change)="onChangeCentralLineSub($event,'MAC022C')">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsCentral===true}" for="chkmaximalsterileNo">
                                    {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="item.groupAttributeId==='MAC022B'" [ngClass]="{'text-muted':(MIPS76Disabled || MAC022BDisabled)}"  class="form-row pl-5 pb-1 pb-sm-2 pb-md-2 align-items-center">
                            <div class="form-check">
                                <input formControlName="MAC022B"  class="form-check-input" type="radio" value="1" id="chkSterile" name="MAC022B" (change)="onChangeCentralLineSub($event,'MAC022B')">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsCentral=== true}" for="chkSterile">
                                    {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="card" id="accordionQuantum31" *ngIf="!disablemeasure">
                    <div class="card-header font-weight-bold"   id="headerQuantum31" style="background-color:#D5DCE6">
                        <div class="form-row align-items-center">
                            <div class="col-sm-11 col-xs-11 col-md-11 col-lg-11" [ngClass]="{'text-muted': IsQuantum31NA}">
                                Quantum 31 - Central Line Ultrasound Guidance (all patients)
                            </div>
                            <div class="col-sm-1 col-xs-1 col-md-1 col-lg-1">
                                <a [ngClass]="(IsQuantum31NA=== false)?'btn btn-link':'btn btn-link collapsed disabled-link'" href="javascript:void(0);" data-toggle="collapse" data-target="#collapseQuantum31" aria-expanded={{IsQuantum31NA}} aria-controls="collapseQuantum31">
                                    <i class="fa fa-angle-double-down fa-2x" [ngStyle]="{'color': (IsQuantum31NA=== false) ? 'black' : 'gray' }" aria-hidden="true"> </i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="collapseQuantum31" [ngClass]="(IsQuantum31NA=== false)?'collapse show':'collapse'" aria-labelledby="headerQuantum31" data-parent="#accordionQuantum31">    
                        <div class="card-body">
                        
                            <div *ngFor="let item of Quantum31List;" [ngClass]="{'text-muted': IsCaseCompleted}">                          
                            <div  *ngIf="item.groupAttributeId==='MAC306'" class="form-row pb-1 pb-sm-2 align-items-center">
                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC306'].errors?.required===true}">  {{item.groupAttributeName}}</label>
                                    </div>
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class=" form-check">
                                        <input formControlName="MAC306" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMAC306" name="MAC306" (change)="onChangeMAC306($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC306'].errors?.required===true}"  for="rdNonEmergentYesMAC306">
                                            Yes
                                        </label>
                                    </div>  
                                </div>
                                <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-1 pb-sm-0 pb-md-0 pb-lg-0">
                                    <div class="form-check">
                                        <input formControlName="MAC306" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMAC306" name="MAC306" (change)="onChangeMAC306($event)">
                                        <label class="form-check-label" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC306'].errors?.required===true}" for="rdNonEmergentNoMAC306">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-6 col-sm-4 col-md-4 col-lg-6"></div>
                            </div>
                            <div *ngIf="item.groupAttributeId==='MAC307'" [ngClass]="{'text-muted': (Quan31Disabled || MAC307Disabled)}" class="form-row pb-1 pb-sm-2 pb-md-2 align-items-center">
                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class="form-check">
                                            <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': hasErrorsMAC306===true}"> {{item.groupAttributeName}}</label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class=" form-check">
                                            <input formControlName="MAC307" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMAC307" name="MAC307" (change)="onChangeMAC307($event)">
                                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsMAC306===true}" for="rdNonEmergentYesMAC307">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-1 pb-sm-0 pb-md-0 pb-lg-0">
                                        <div class="form-check">
                                            <input formControlName="MAC307" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMAC307" name="MAC307" (change)="onChangeMAC307($event)">
                                            <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsMAC306===true}" for="rdNonEmergentNoMAC307">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-4 col-md-4 col-lg-6"></div>
                                </div>
                                <div *ngIf="item.groupAttributeId==='MAC308'" [ngClass]="{'text-muted': (Quan31Disabled || MAC308Disabled)}" class="form-row pb-1 pb-sm-2 pb-md-2 align-items-center">
                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-0 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                            <div class="form-check">
                                                <label class="form-check-label font-weight-bold" [ngClass]="{'text-danger': hasErrorsMAC307===true}"> {{item.groupAttributeName}}</label>
                                            </div>
                                        </div>
                                        <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                                            <div class="form-check">
                                                <input formControlName="MAC308" class="form-check-input" type="radio" value="1" id="rdNonEmergentYesMACMAC308" name="MAC308" (change)="onChangeMAC308($event)">
                                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorsMAC307===true}" for="rdNonEmergentYesMACMAC308">
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pl-5">
                                            <div class="form-check">
                                                <input formControlName="MAC308" class="form-check-input" type="radio" value="0" id="rdNonEmergentNoMACMAC308" name="MAC308" (change)="onChangeMAC308($event)">
                                                <label class="form-check-label" [ngClass]="{'text-danger':  hasErrorsMAC307===true}" for="rdNonEmergentNoMACMAC308">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-sm-4 col-md-4 col-lg-6"></div>
                                    </div>                                
                                </div>   
                        </div>
                    </div>
            </div>
            
            <!-- <div class="card" id="accordion44">
                <div class="card-header font-weight-bold"   id="header44" style="background-color:#D5DCE6">
                    <div class="form-row align-items-center">
                        <div class="col-sm-11 col-xs-11 col-md-11 col-lg-11">
                            MIPS 44 - Perioperative Beta Blocker in Patients with Isolated CABG Surgery (18 & older)
                        </div>
                        <div class="col-sm-1 col-xs-1 col-md-1 col-lg-1">
                            <a [ngClass]="(IsMIPS44NA=== false)?'btn btn-link':'btn btn-link collapsed'" href="javascript:void(0);" data-toggle="collapse" data-target="#collapse44" aria-expanded={{IsMIPS44NA}} aria-controls="collapse44">
                                <i class="fa fa-angle-double-down fa-2x" style="color:black" aria-hidden="true"> </i>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="collapse44"  [ngClass]="(IsMIPS44NA=== false)?'collapse show':'collapse'" aria-labelledby="header44" data-parent="#accordion44">    
                    <div class="card-body">
                    <div *ngFor="let item of MIPS44List;"> 
                        <div *ngIf="item.groupAttributeId==='MAC216'" class="form-row pb-2 pb-sm-2 pb-md-2 pb-lg-0 align-items-center">
                            <div class="form-check ">
                                <input type="radio" value="1" class="form-check-input"   id="chkPreoperativeNA" name="MAC216" formControlName="MAC216" (change)="onChangeMIPS44('MAC216')">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrors===true}" for="chkPreoperativeNA">
                                    {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="item.groupAttributeId==='MAC217'" class="form-row pb-2 pb-sm-2 pb-md-2 pb-lg-0 align-items-center">
                            <div class="form-check">
                                <input type="radio" value="1" class="form-check-input"   id="chkBetaSugicalIncision" name="MAC217" formControlName="MAC217" (change)="onChangeMIPS44('MAC217')">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrors===true}" for="chkBetaSugicalIncision">
                                    {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="item.groupAttributeId==='MAC218'" class="form-row pb-2 pb-sm-2 pb-md-2 pb-lg-0  align-items-center">
                            <div class="form-check">
                                <input type="radio"  value="1" class="form-check-input"    id="chkBetaSugicalIncisiNotSpecified" name="MAC218" formControlName="MAC218" (change)="onChangeMIPS44('MAC218')">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrors===true}" for="chkBetaSugicalIncisiNotSpecified">
                                    {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div> 
                        <div  *ngIf="item.groupAttributeId==='MAC219'" class="form-row pb-2 pb-sm-2 pb-md-2 pb-lg-0   align-items-center">
                            <div class="form-check">
                                <input type="radio" value="1" class="form-check-input"  id="chkMedicalSugicalIncision" name="MAC219" formControlName="MAC219" (change)="onChangeMIPS44('MAC219')">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrors===true}" for="chkMedicalSugicalIncision">
                                    {{item.groupAttributeName}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div> -->
            <!--  END  -->   
        </div>
        <div class="card" >
            <div class="card-header font-weight-bold" style="background-color:#D5DCE6">Other</div>
                <div class="card-body" [ngClass]="{'text-muted': IsCaseCompleted}">
                    <div class="form-row pb-2 align-items-center">
                        <div class="col-xs-3 col-sm-4 col-md-3 col-lg-3 ">
                            <label for="drpTransferofcare" class="form-check-label font-weight-bold" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC706'].errors?.required===true}">Transfer of Care</label>
                        </div>
                        <div class="col-xs-4 col-sm-5 col-md-4 col-lg-4">
                            <select formControlName="MAC706" class="form-control" [ngClass]="{'border-danger': isFormSubmitted && detailform.controls['MAC706'].errors?.required===true}" id="drpTransferofcare" (change)="SetChangeValues();">
                                <option *ngFor="let val of this.Transferofcare" value={{val.groupAttributeSubItemId}}>
                                    {{val.groupAttributeSubItem}}
                                </option>
                            </select>
                        </div>
                        <div class="col-xs-5 col-sm-4 col-md-5 col-lg-5"></div>
                    </div>
                    <div class="form-row pb-2 align-items-center">
                        <div class="col-xs-3 col-sm-4 col-md-3 col-lg-3 ">
                            <label for="drpHandoff" class="form-check-label font-weight-bold" [ngClass]="{'text-danger': isFormSubmitted && detailform.controls['MAC707'].errors?.required===true}">Handoff Protocol/checklist followed</label>
                        </div>
                        <div class="col-xs-4 col-sm-5 col-md-4 col-lg-4">
                            <select formControlName="MAC707" class="form-control" [ngClass]="{'border-danger': isFormSubmitted && detailform.controls['MAC707'].errors?.required===true}" name="MAC707" id="drpHandoff" (change)="SetChangeValues();">
                                <option *ngFor="let val of this.HandOffProtocol" value={{val.groupAttributeSubItemId}}>
                                    {{val.groupAttributeSubItem}}
                                </option>
                            </select>
                        </div>
                        <div class="col-xs-5 col-sm-4 col-md-5 col-lg-5"></div>
                    </div>
                    <div class="form-row pb-5 align-items-center"></div>
                    <div class="form-row pb-2 align-items-center">
                        <div class="col-sm-4"></div>

                        <div class="col-sm-2">
                            <label for="lblProviderName" class="col-form-label align-content-end font-weight-bold pull-right">Provider Name: </label>
                        </div>
                        <div class="col-sm-2">
                            <label class="col-form-label align-content-end pull-right">{{this.pProviderName}}</label>
                        </div>
                        <div class="col-sm-2">
                            <label for="lblDateSigned" class="col-form-label font-weight-bold pull-right">Date Signed: </label>                           
                        </div>
                        <div class="col-sm-2">                            
                            <label class="col-form-label align-content-end pull-right"> {{this.date | date:'MM/dd/yyyy' }}</label>                            
                        </div>
                    </div>
                    <!-- <div class="form-row align-items-center">
                        <div class="col-xs-3 col-sm-4 col-md-3 col-lg-3">
                            <label class="form-check-label">Adult PONV Pt.-Specific Risk Factors</label>
                        </div>
                        <div class="col-sm-1 col-md-1 col-xs-1 col-lg-1">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAdultPONVPatSpecific===true}" for="chkFemale">
                                    <input formControlName="MAC704B" class="form-check-input" type="checkbox"  id="chkFemale" name="MAC704B" (change)="onChangeAdultPONVPatient($event)" >
                                    Female
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-8 col-md-8 col-xs-1 col-lg-1"></div>
                    </div>
                    <div class="form-row align-items-center">
                        <div class="col-sm-7 offset-sm-4 col-xs-4 offset-xs-3 col-md-4 offset-md-3 col-lg-4 offset-lg-3">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAdultPONVPatSpecific===true}" for="chkMotionSickness">
                                    <input formControlName="MAC704C" class="form-check-input" type="checkbox"  id="chkMotionSickness" name="MAC704C" (change)="onChangeAdultPONVPatient($event)" >
                                    History of PONV and Motion Sickness
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-1 col-xs-5 col-md-5 col-lg-5"></div>
                    </div>
                    <div class="form-row align-items-center">
                        <div class="col-sm-4 offset-sm-4 col-xs-4 offset-xs-3 col-md-4 offset-md-3 col-lg-4 offset-lg-3">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAdultPONVPatSpecific===true}" for="chkNonSmoker">
                                    <input formControlName="MAC704D" class="form-check-input"  type="checkbox" id="chkNonSmoker" name="MAC704D" (change)="onChangeAdultPONVPatient($event)">
                                    Non Smoker
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-5 col-xs-5 col-md-5 col-lg-5"></div>
                    </div>
                    <div class="form-row align-items-center">
                        <div class="col-sm-7 offset-sm-4 col-xs-4 offset-xs-3 col-md-4 offset-md-3 col-lg-4 offset-lg-3">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAdultPONVPatSpecific===true}" for="chkOperativeOpioids">
                                    <input formControlName="MAC704E" class="form-check-input" type="checkbox"  id="chkOperativeOpioids" name="MAC704E" (change)="onChangeAdultPONVPatient($event)">
                                    Need for post operative opioids
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-1 col-xs-5 col-md-5 col-lg-5"></div>
                    </div>
                    <div class="form-row align-items-center">
                        <div class="col-sm-4 offset-sm-4 col-xs-4 offset-xs-3 col-md-4 offset-md-3 col-lg-4 offset-lg-3">
                            <div class=" form-check">
                                <label class="form-check-label" [ngClass]="{'text-danger': hasErrorAdultPONVPatSpecific===true}" for="chkNotApplicable">
                                    <input formControlName="MAC704A" class="form-check-input" type="checkbox" value="" id="chkNotApplicable" name="MAC704A" (change)="onChangeAdultPONVPatient($event)">
                                    Not Applicable
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-5 col-xs-5 col-md-5 col-lg-5"></div>
                    </div> -->
                </div>
        </div>
        <div *ngIf="!true">
            <div class="form-row">
                <div class="col-md-12">
                    <div class="card" >
                        <div class="card-header font-weight-bold" style="background-color:#D5DCE6">Clinician Signature</div>
                        <div class="form-check-label ml-4">
                            <label class="col-form-label pr-3" id="MAC033">{{name}} </label>
                            <label class="col-form-label" id="MAC034">{{date}} </label>
                        </div>
                        <input type="button" formControlName="BtnSign" class="btn btn-outline-danger ml-4 mb-2 mr-4 rounded"  value="Add Signature" (click)="changeDate()">
                        <label [ngClass]="{'text-danger': hasErrorsSignature===true}" *ngIf="signatureError" class="ml-4"> Signature required!</label>
                    </div>
                </div>
            </div>
        </div>
        <!-- <p class="text-primary mt-3">Note: To report a clinical occurrence /adverse event go to CareTool Tab</p>  -->
    </form>
</div>
